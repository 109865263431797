import createBehavior from '@js/functions/createBehavior.js';
import loadScript from '@js/functions/load-script.js';

const chatbotBehavior = createBehavior(
  'chatbot',
  {
    hideChatbot() {
      const askmonaWidget = document.querySelector('#askmona-widget')
      if (askmonaWidget) {
        askmonaWidget.style.display = 'none';
      }
    },
    unhideChatbot() {
      const askmonaWidget = document.querySelector('#askmona-widget')
      if (askmonaWidget) {
        askmonaWidget.style.display = 'block';
      }
    },
    loadChatSDK() {
      return loadScript('https://webchat.askmonastudio.com/scripts/embed.mjs').then((data) => {
        const url = new URL(window.location.href);
        Askmona.start({
          wat: this.options['app-id'],
        });
        return data
      });
    },
  },
  {
    init() {
      const askmonaWidget = document.querySelector('#askmona-widget')
      this.unhideChatbot()
      if (askmonaWidget) {
        setTimeout(() => {
          askmonaWidget.firstElementChild && askmonaWidget.firstElementChild.classList.remove('mona-widget--hidden')
        })
      } else {
        this.loadChatSDK()
      }

      document.addEventListener('nav:opened', this.hideChatbot)
      document.addEventListener('nav:closed', this.unhideChatbot)
    },
    destroy() {
      const askmonaWidget = document.querySelector('#askmona-widget')
      askmonaWidget.firstElementChild && askmonaWidget.firstElementChild.classList.add('mona-widget--hidden')
      document.removeEventListener('nav:opened', this.hideChatbot)
      document.removeEventListener('nav:closed', this.unhideChatbot)
    }
  }
);

export default chatbotBehavior;
